@use 'common'

@font-face
	font-family: 'smoothycursive'
	src: url('/fonts/smoothy-cursive-webfont.woff2') format('woff2'), url('/fonts/smoothy-cursive-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal

@font-face
	font-family: 'Switzer-VariableItalic'
	src: url('/fonts/Switzer-VariableItalic.woff2') format('woff2'), url('/fonts/Switzer-VariableItalic.woff') format('woff'), url('/fonts/Switzer-VariableItalic.ttf') format('truetype')
	font-weight: 100 900
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Thin'
	src: url('/fonts/Switzer-Thin.woff2') format('woff2'), url('/fonts/Switzer-Thin.woff') format('woff'), url('/fonts/Switzer-Thin.ttf') format('truetype')
	font-weight: 100
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-ThinItalic'
	src: url('/fonts/Switzer-ThinItalic.woff2') format('woff2'), url('/fonts/Switzer-ThinItalic.woff') format('woff'), url('/fonts/Switzer-ThinItalic.ttf') format('truetype')
	font-weight: 100
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Extralight'
	src: url('/fonts/Switzer-Extralight.woff2') format('woff2'), url('/fonts/Switzer-Extralight.woff') format('woff'), url('/fonts/Switzer-Extralight.ttf') format('truetype')
	font-weight: 200
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-ExtralightItalic'
	src: url('/fonts/Switzer-ExtralightItalic.woff2') format('woff2'), url('/fonts/Switzer-ExtralightItalic.woff') format('woff'), url('/fonts/Switzer-ExtralightItalic.ttf') format('truetype')
	font-weight: 200
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Light'
	src: url('/fonts/Switzer-Light.woff2') format('woff2'), url('/fonts/Switzer-Light.woff') format('woff'), url('/fonts/Switzer-Light.ttf') format('truetype')
	font-weight: 300
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-LightItalic'
	src: url('/fonts/Switzer-LightItalic.woff2') format('woff2'), url('/fonts/Switzer-LightItalic.woff') format('woff'), url('/fonts/Switzer-LightItalic.ttf') format('truetype')
	font-weight: 300
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Regular'
	src: url('/fonts/Switzer-Regular.woff2') format('woff2'), url('/fonts/Switzer-Regular.woff') format('woff'), url('/fonts/Switzer-Regular.ttf') format('truetype')
	font-weight: 400
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-Italic'
	src: url('/fonts/Switzer-Italic.woff2') format('woff2'), url('/fonts/Switzer-Italic.woff') format('woff'), url('/fonts/Switzer-Italic.ttf') format('truetype')
	font-weight: 400
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Medium'
	src: url('/fonts/Switzer-Medium.woff2') format('woff2'), url('/fonts/Switzer-Medium.woff') format('woff'), url('/fonts/Switzer-Medium.ttf') format('truetype')
	font-weight: 500
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-MediumItalic'
	src: url('/fonts/Switzer-MediumItalic.woff2') format('woff2'), url('/fonts/Switzer-MediumItalic.woff') format('woff'), url('/fonts/Switzer-MediumItalic.ttf') format('truetype')
	font-weight: 500
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Semibold'
	src: url('/fonts/Switzer-Semibold.woff2') format('woff2'), url('/fonts/Switzer-Semibold.woff') format('woff'), url('/fonts/Switzer-Semibold.ttf') format('truetype')
	font-weight: 600
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-SemiboldItalic'
	src: url('/fonts/Switzer-SemiboldItalic.woff2') format('woff2'), url('/fonts/Switzer-SemiboldItalic.woff') format('woff'), url('/fonts/Switzer-SemiboldItalic.ttf') format('truetype')
	font-weight: 600
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Bold'
	src: url('/fonts/Switzer-Bold.woff2') format('woff2'), url('/fonts/Switzer-Bold.woff') format('woff'), url('/fonts/Switzer-Bold.ttf') format('truetype')
	font-weight: 700
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-BoldItalic'
	src: url('/fonts/Switzer-BoldItalic.woff2') format('woff2'), url('/fonts/Switzer-BoldItalic.woff') format('woff'), url('/fonts/Switzer-BoldItalic.ttf') format('truetype')
	font-weight: 700
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Extrabold'
	src: url('/fonts/Switzer-Extrabold.woff2') format('woff2'), url('/fonts/Switzer-Extrabold.woff') format('woff'), url('/fonts/Switzer-Extrabold.ttf') format('truetype')
	font-weight: 800
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-ExtraboldItalic'
	src: url('/fonts/Switzer-ExtraboldItalic.woff2') format('woff2'), url('/fonts/Switzer-ExtraboldItalic.woff') format('woff'), url('/fonts/Switzer-ExtraboldItalic.ttf') format('truetype')
	font-weight: 800
	font-display: swap
	font-style: italic

@font-face
	font-family: 'Switzer-Black'
	src: url('/fonts/Switzer-Black.woff2') format('woff2'), url('/fonts/Switzer-Black.woff') format('woff'), url('/fonts/Switzer-Black.ttf') format('truetype')
	font-weight: 900
	font-display: swap
	font-style: normal

@font-face
	font-family: 'Switzer-BlackItalic'
	src: url('/fonts/Switzer-BlackItalic.woff2') format('woff2'), url('/fonts/Switzer-BlackItalic.woff') format('woff'), url('/fonts/Switzer-BlackItalic.ttf') format('truetype')
	font-weight: 900
	font-display: swap
	font-style: italic

html
	scroll-behavior: smooth

html,
body
	color: common.$niceColor
	padding: 0
	margin: 0
	font-family: common.$switzer

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box

.commingSoon
	background-color: #FFE00A
	height: 100vh
	width: 100%
	display: flex
	align-items: center
	justify-content: center
	&-inner
		overflow: hidden
		max-width: 95%
		text-align: center
		&-logo
			svg
				width: 350px
				height: auto
				max-width: 100%
		p
			font-family: 'Nunito', sans-serif
			color: #4DB375
			font-size: 18px
			text-transform: uppercase
			font-weight: 900
			max-width: 500px
		&-form
			form
				input
					background-color: transparent
					border: none
					border: 3px solid #4DB375
					border-radius: 0
					font-size: 24px
					padding: 15px
					color: #4DB375
					font-family: 'Nunito', sans-serif
					font-weight: 900
					-webkit-appearance: none
					box-sizing: border-box
					max-width: 100%
					&:focus
						outline: none
					&::placeholder
						color: #4DB375
						font-family: 'Nunito', sans-serif
						font-weight: 900
				button
					background-color: transparent
					padding: 0
					border: none
					margin-top: 10px
					font-size: 24px
					color: #4DB375
					font-family: 'Nunito', sans-serif
					font-weight: 900
					cursor: pointer

.section
	padding: 60px 0
	@media(max-width: 768px)
		padding: 30px 0
.sectionMargin
	margin: 60px 0
	@media(max-width: 768px)
		margin: 30px 0

.swiper-pagination-bullet-active
	background: common.$yellow!important

.swiper-button-next, .swiper-button-prev
	color: common.$yellow!important

.orderStatus
	padding: 100px 0
	color: #000
	text-align: center
	max-width: 400px
	margin: 0 auto

.sectionTitle
	text-align: center
	max-width: 700px
	margin: 0 auto 50px auto
	font-size: 32px
	color: #000
	@media(max-width: 768px)
		font-size: 24px
		margin: 0 auto 30px auto

h1, h2, h3, h4, h5
	font-weight: 600
	color: #000

p
	color: #000

.content
	.inspiration:first-child
		margin-top: 60px
		@media(max-width: 768px)
			margin-top: 40px
	.configurator:first-child
		margin-top: 60px
		@media(max-width: 768px)
			margin-top: 40px
	&-sideHeader
		> div
			display: flex

.contentWrapper
	width: 100%
	@media(max-width: 768px)
		padding-left: 20px
		padding-right: 20px
		> section
			margin-left: -20px
			width: calc(100% + 40px)

.submitted_configurator
	padding: 0 20px
	display: flex
	//flex-wrap: wrap
	margin-top: 100px
	width: 100%
	max-width: 1200px
	margin: 100px auto 0 auto
	h1
		font-size: 32px
	@media(max-width: 768px)
		flex-wrap: wrap
		//flex-direction: column-reverse
		margin-top: 50px
		h1
			font-size: 24px
		&-recap
			width: 100%
			flex-direction: row-reverse
			order: -1
